export const ROLES = {
    UNITE_SuperAdmin: 'SuperAdmin',
    UNITE_AdminUser: 'AdminUser',
    UNITE_User: 'User',
    UNITE_IT: 'IT',
    dematic_p_unite: 'External'
};

export const RoleMapping = {
    "Unite.Admin.User": "UNITE_AdminUser",
    "Unite.Super.Admin": "UNITE_SuperAdmin",
    "Unite.User": "UNITE_User",
    "Unite.IT": "UNITE_IT",
    "dematic_p_unite": "dematic_p_unite"
};
